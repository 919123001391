<template>
  <div>
    <qzf-tabs v-model:value="value">
      <qzf-tabs-item label="税局" name="1" :cache="false">
        <aiFinanceSj ref="aiFinanceSj"></aiFinanceSj>
      </qzf-tabs-item>
      <qzf-tabs-item label="社保" name="2" :cache="false">
        <aiFinanceSb ref="aiFinanceSb"></aiFinanceSb>
      </qzf-tabs-item>
    </qzf-tabs>
  </div>
</template>

<script>
import aiFinanceSj from "./components/aiFinanceSj.vue";
import aiFinanceSb from "./components/aiFinanceSb.vue";
export default {
  name: "autoHomeIndex",
  components: { aiFinanceSj, aiFinanceSb },
  data() {
    return {
      value: "1",
    };
  },
  mounted() {
    this.initBus();
  },

  methods: {
    initBus() {
      this.$bus.off("confirmStatusUpdate");
      this.$bus.on("confirmStatusUpdate", (val) => {
        setTimeout(() => {
          this.$refs.aiFinanceSj.getList();
          this.$refs.aiFinanceSb.getList();
        });
      });
    },
  },
};
</script>

<style></style>
