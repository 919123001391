<template>
  <div>
    <div class="auto_content">
      <div class="auto_content_size">
        <!-- <div style="width: 50px">统计</div> -->
        <div style="margin-right:10px;margin-bottom: 6px">
          <el-button icon="RefreshRight" size="small" @click="getNewList()" style="margin-top:5px"></el-button>
          <el-select v-model="listQuery.orgId" placeholder="请选择代账公司"  style="width: 120px;margin: 5px 0 0;" filterable clearable size="small" @change="orgChange">
            <el-option
              v-for="item in orgList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
              <span v-if="item.count == 0" style="color:red">({{ item.count }})</span>
              <span v-else>({{ item.count }})</span>
              <span>{{' '+item.name }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div class="each_text">
            <div>企业总数</div>
            <span>{{ info.countTotal }}</span>家
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zidong"></i>
          </div>
          <div
            class="each_text"
          >
            <div>授权在线</div>
            <span>{{ info.countEmpower }}</span>家
          </div>
        </div>
        <div class="each"  @click="getNewListTotal()">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div
            class="each_text"
          >
            <div>本期用户</div>
            <a>
              <span>{{ info.countPeriod }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getWjz">
          <div class="icon_bg">
            <i class="iconfont icon-kehuguanli"></i>
          </div>
          <div
            class="each_text"
          >
            <div>异常</div>
            <a><span>{{ info.countYc }}</span>家</a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(1)">
          <div class="icon_bg">
            <i class="iconfont icon-piaojucaiji1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>已完成</div>
            <a>
              <span>{{ info.countOk }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(6)">
          <div class="icon_bg">
            <i class="iconfont icon-piaojucaiji1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>未完成</div>
            <a>
              <span>{{ info.countWwc }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(2)">
          <div class="icon_bg">
            <i class="iconfont icon-lishishuju1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>进行中</div>
            <a>
              <span>{{ info.countJxz }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(3)">
          <div class="icon_bg">
            <i class="iconfont icon-shouquanyemian1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>待确认</div>
            <a>
              <span>{{ info.countDqr }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(4)">
          <div class="icon_bg">
            <i class="iconfont icon-chushihuacaozuo"></i>
          </div>
          <div
            class="each_text"
          >
            <div>未开始</div>
            <a>
              <span>{{ info.countWks }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each" @click="getNewListTotal(5)">
          <div class="icon_bg">
            <i class="iconfont icon-jieyuekehu1"></i>
          </div>
          <div
            class="each_text"
          >
            <div>失败</div>
            <a>
              <span>{{ info.countErr }}</span
              >家
            </a>
          </div>
        </div>
      </div>
      <div class="date_select">
        <div>
          <qzf-search-period v-model:period="listQuery.period" :clearable="false" style="width:120px" @change="getNewList"></qzf-search-period>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane name="税款申报">
        <template #label>
          税款申报({{ info.countSkSb }})
        </template>
      </el-tab-pane>
      <el-tab-pane name="零申报">
        <template #label>
          零申报({{ info.countLsb }})
        </template>
      </el-tab-pane>
    <div class="top_select">
      <div>
        <el-button icon="RefreshRight" size="small" @click="getList()"></el-button>
        <el-input size="small" placeholder="公司名称" v-model.trim="listQuery.name" style="width:160px;margin-left: 5px" @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-scrollbar :height="350">
            <el-form style="margin:11px 0px 0px 15px;width:670px" label-width="130px" class="style_form" size="small" :inline="true">
            <el-form-item label="纳税人类型：">   
              <selecttaxtype v-model:type="listQuery.type" style="width:140px"></selecttaxtype>
            </el-form-item>
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px"></selectuser>
            </el-form-item>
            <el-form-item label="税局："> 
              <selectcity v-model:citys="listQuery.districtCode" style="width:140px"></selectcity>
            </el-form-item>
            <el-form-item label="登录状态：">   
              <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:140px"></selectAccreditStatus>
            </el-form-item>
            <el-form-item label="清册状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.qcStatus" placeholder="请选择清册状态"/>
            </el-form-item>
            <el-form-item label="清册失败原因："> 
              <el-input v-model="listQuery.qcErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="勾选状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.gxStatus" placeholder="请选择勾选状态"/>
            </el-form-item>
            <el-form-item label="勾选失败原因："> 
              <el-input v-model="listQuery.gxErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="发票采集状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.fpcjStatus" placeholder="请选择发票采集状态" loadingText="采集中"/>
            </el-form-item>
            <el-form-item label="发票采集失败原因："> 
              <el-input v-model="listQuery.fpcjErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="银行采集状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.yhcjStatus" placeholder="请选择银行采集状态" loadingText="采集中"/>
            </el-form-item>
            <el-form-item label="银行采集失败原因："> 
              <el-input v-model="listQuery.yhcjErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="单据确认状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.djqrStatus" placeholder="请选择单据确认状态" type="qr"/>
            </el-form-item>
            <el-form-item label="单据确认失败原因："> 
              <el-input v-model="listQuery.djqrErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="个税采集状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.gscjStatus" placeholder="请选择个税采集状态" loadingText="采集中"/>
            </el-form-item>
            <el-form-item label="个税采集失败原因："> 
              <el-input v-model="listQuery.gscjErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="批量补充状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.plbcStatus" placeholder="请选择批量补充状态" loadingText="补充中"/>
            </el-form-item>
            <el-form-item label="批量补充失败原因："> 
              <el-input v-model="listQuery.plbcErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="生成凭证状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.scpzStatus" placeholder="请选择生成凭证状态" loadingText="生成中"/>
            </el-form-item>
            <el-form-item label="生成凭证失败原因："> 
              <el-input v-model="listQuery.scpzErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="结账状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.jzStatus" placeholder="请选择结账状态" loadingText="结账中"/>
            </el-form-item>
            <el-form-item label="结账失败原因："> 
              <el-input v-model="listQuery.jzErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="质检确认状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.zjStatus" placeholder="请选择质检确认状态" type="zj"/>
            </el-form-item>
            <el-form-item label="质检确认失败原因："> 
              <el-input v-model="listQuery.zjErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="税款确认状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.skqrStatus" placeholder="请选择税款确认状态" type="qr"/>
            </el-form-item>
            <el-form-item label="税款确认失败原因："> 
              <el-input v-model="listQuery.skqrErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="申报状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.sbStatus" placeholder="请选择申报状态" loadingText="申报中"/>
            </el-form-item>
            <el-form-item label="申报失败原因："> 
              <el-input v-model="listQuery.sbErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="扣款状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.kkStatus" placeholder="请选择扣款状态" loadingText="扣款中"/>
            </el-form-item>
            <el-form-item label="扣款失败原因："> 
              <el-input v-model="listQuery.kkErr" clearable></el-input>
            </el-form-item>
            <el-form-item label="检查状态："> 
              <autoHomeSelect v-model:modelValue="listQuery.jcStatus" placeholder="请选择检查状态" loadingText="检查中"/>
            </el-form-item>
            <el-form-item label="检查失败原因："> 
              <el-input v-model="listQuery.jcErr" clearable></el-input>
            </el-form-item>
           </el-form>
         </el-scrollbar>
        </search>
        <span v-show="activeName == '零申报'" style="color: red;margin-left: 5px;font-size: 13px;"><i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c;margin-right: 5px;"></i>温馨提示：本栏目会跳过采集数据，如不确认是否有数据，请移到税款申报</span>
      </div>
      <div>
        <el-button size="small" type="success" @click="reStart" icon="SwitchButton" :disabled="havingTask">重启</el-button>
        <el-button size="small" type="success" @click="autoStart" :disabled="havingTask"><i class="iconfont icon-a-xinjianzidingyichicun21x1" ></i>调试</el-button>
        <el-button size="small" type="primary" @click="setting" icon="Setting">做账习惯配置</el-button>
      </div>
    </div>

    <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" border @selection-change="handleSelectionChange" v-loading="loading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column prop="name" label="公司名称" min-width="240" fixed="left">
        <template #default="scope">
          <TagNameCopy :row="scope.row" idKey="comId">
            <template v-slot>
              <el-tooltip content="做账习惯配置" placement="top" effect="dark">
                <i class="iconfont icon-guanlishezhi" style="color:var(--themeColor,#17a2b8);cursor: pointer;font-size: 15px;margin-left:8px" @click="settingOne(scope.row)">
                </i>
              </el-tooltip>
            </template>
          </TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column label="初始化" align="center">
        <el-table-column label="清册"  width="130">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.qc_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.qc_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.qc_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.qc_status)">{{$filterAutoStatusText(scope.row.qc_status)}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.qc_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" v-if="scope.row.qc_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过清册">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'qc','清册')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="勾选"  width="130">
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.type == '2'">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.gx_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.gx_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.gx_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.gx_status)">{{$filterAutoStatusText(scope.row.gx_status)}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.gx_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" v-if="scope.row.gx_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过勾选">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'gx','勾选')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
            <div v-else>
              --
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="自动单据采集" align="center">
        <el-table-column label="发票/银行采集"  width="190">
          <template #default="scope">
            <div class="item_icon div_p">
              <p>
                发票：
                <i :class="$filterAutoStatusIcon(scope.row.fpcj_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.fpcj_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.fpcj_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.fpcj_status)">{{$filterAutoStatusText(scope.row.fpcj_status,'cj')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.fpcj_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-button @click="checkInvoice(scope.row)" size="small" link style="padding: 4px 3px;margin-left: 0;">查看</el-button>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" style="margin-left: 2px;" v-if="scope.row.fpcj_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过发票采集">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'fpcj','发票采集')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
            <div class="item_icon div_p">
              <p>
                银行：
                <i :class="$filterAutoStatusIcon(scope.row.yhcj_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.yhcj_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.yhcj_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.yhcj_status)">{{$filterAutoStatusText(scope.row.yhcj_status,'cj')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.yhcj_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-button @click="checkBank(scope.row)" size="small" link style="padding: 4px 3px;margin-left: 0;">查看</el-button>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" style="margin-left: 2px;" v-if="scope.row.yhcj_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过银行采集">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'yhcj','银行采集')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="单据确认"  width="120">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.djqr_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.djqr_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.djqr_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.djqr_status)">{{$filterAutoStatusText(scope.row.djqr_status,'qr')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.djqr_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
                <el-button @click="confirmAuto(scope.row,'djqr','单据确认')" v-if="scope.row.djqr_status==2"  size="small" link style="padding: 4px 3px;margin-top: -1px;">确认</el-button>
              </p>
              <div>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" v-if="scope.row.djqr_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过单据确认">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'djqr','单据确认')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="累计/专项扣除采集"  width="190">
          <template #default="scope">
            <div class="item_icon div_p">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.gscj_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.gscj_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.gscj_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.gscj_status)">{{$filterAutoStatusText(scope.row.gscj_status,'cj')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.gscj_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-button @click="checkInvoice(scope.row)" disabled size="small" link style="padding: 4px 3px;margin-left: 0;">查看</el-button>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" style="margin-left: 2px;" v-if="scope.row.gscj_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过累计/专项扣除采集">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'gscj','累计/专项扣除采集')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="Ai账务处理" align="center">
        <el-table-column label="批量补充"  width="130">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.bc_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.bc_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.bc_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.bc_status)">{{$filterAutoStatusText(scope.row.bc_status,'bc')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.bc_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" v-if="scope.row.bc_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过批量补充">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'bc','批量补充')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="生成凭证"  width="130">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.pz_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.pz_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.pz_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.pz_status)">{{$filterAutoStatusText(scope.row.pz_status,'sc')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.pz_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" v-if="scope.row.pz_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过生成凭证">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'pz','生成凭证')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="结账"  width="130">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.jz_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.jz_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.jz_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.jz_status)">{{$filterAutoStatusText(scope.row.jz_status,'jz')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.jz_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" v-if="scope.row.jz_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过结账">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'jz','结账')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="质检确认" width="150">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.zj_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.zj_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.zj_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.zj_status)">{{$filterAutoStatusText(scope.row.zj_status,'zj')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.zj_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <el-button @click="confirmAuto(scope.row,'zj','质检确认')" v-if="scope.row.zj_status == 2" :disabled="!$buttonStatus('zdjz_qr')" size="small" link style="padding: 4px 3px;margin-top: -1px;">确认</el-button>
              <el-tooltip effect="dark" placement="top-start" content="继续">
                <el-button link size="small" :disabled="scope.row.btnStatus || !$buttonStatus('zdjz_jx')" v-if="scope.row.zj_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
              </el-tooltip>
              <el-tooltip effect="dark" placement="top-start" content="跳过质检确认">
                <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'zj','质检确认')" v-if="scope.row.qc_status"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="税款确认" width="170">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$filterAutoStatusIcon(scope.row.skqr_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.skqr_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.skqr_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.skqr_status)">{{$filterAutoStatusText(scope.row.skqr_status,'qr')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.skqr_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-button @click="confirmAuto(scope.row,'skqr','税款确认')" v-if="scope.row.skqr_status == 2" size="small" link style="padding: 4px 3px;margin-top: -1px;">确认</el-button>
                  <el-button @click="checkTableDialog(scope.row,'')" size="small" link style="padding: 4px 3px;margin-top: -1px;margin-left: 0;" :disabled="scope.row.skqr_status == 0">查看</el-button>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" v-if="scope.row.skqr_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过税款确认">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'skqr','税款确认')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="国税报税/检查" align="center">
        <el-table-column label="申报/扣款"  width="195">
          <template #default="scope">
            <div class="item_icon div_p">
              <p>
                申报：
                <i :class="$filterAutoStatusIcon(scope.row.sb_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.sb_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.sb_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.sb_status)">{{$filterAutoStatusText(scope.row.sb_status,'sb')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.sb_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-button @click="checkTableDialog(scope.row,'sb')" size="small" link style="padding: 4px 3px;margin-top: -1px;" :disabled="scope.row.sb_status == 0">查看</el-button>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" style="margin-left: 0px;" v-if="scope.row.sb_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过申报">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'sb','申报')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
            <div class="item_icon div_p">
              <p>
                扣款：
                <i :class="$filterAutoStatusIcon(scope.row.kk_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.kk_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.kk_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.kk_status)">{{$filterAutoStatusText(scope.row.kk_status,'kk')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.kk_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-button @click="checkTableDialog(scope.row,'kk')" size="small" type="text" style="padding: 4px 3px;margin-top: -1px;" :disabled="scope.row.kk_status == 0">查看</el-button>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" v-if="scope.row.kk_status==4" @click="continueTask(scope.row)"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过扣款">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'kk','扣款')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="检查"  width="150">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <span v-if="scope.row.image && (scope.row.jc_status == 3 || scope.row.jc_status == 4 || scope.row.jc_status == 5)">
                  <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="(scope.row.jc_status == 4)" @click="open(scope.row, scope.$index,'image')"></i>
                  <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="(scope.row.jc_status == 3)" @click="open(scope.row, scope.$index,'image')"></i>
                  <i class="iconfont icon-picture" style="color:#e6a23c;font-size:16px;" v-if=" (scope.row.jc_status == 5)" @click="open(scope.row, scope.$index,'image')"></i>
                </span>
                <i v-else :class="$filterAutoStatusIcon(scope.row.jc_status)"></i>
                <el-tooltip effect="dark" :disabled="!scope.row.jc_err" placement="top-start">
                  <template #content>
                    <div v-html="scope.row.jc_err"></div>
                  </template>
                  <div style="display: inline-block;">
                    <span :class="$filterAutoStatusWord(scope.row.jc_status)">{{$filterAutoStatusText(scope.row.jc_status,'jc')}}</span>
                    <i class="iconfont icon-wenhao" v-if="scope.row.jc_err" style="color:#f56c6c;margin:0 0 0 4px;"></i>
                  </div>
                </el-tooltip>
              </p>
              <div>
                <el-tooltip effect="dark" placement="top-start" content="重启">
                  <el-button link size="small" :disabled="scope.row.btnStatus" v-if="scope.row.jc_status==4" @click="checkFailStart(scope.row)"><el-icon style="font-size: 15px"><VideoPlay /></el-icon></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="继续">
                  <el-button link size="small" :disabled="scope.row.btnStatus" v-if="scope.row.jc_status==4" @click="continueTask(scope.row)" style="margin-left: 0"><i class="iconfont icon-jiantou-you"></i></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" placement="top-start" content="跳过检查">
                  <i class="iconfont icon-tiaoguo1" @click="skipOption(scope.row,'jc','检查')" v-if="scope.row.qc_status"></i>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="aiFinanceFactory"/>
    </div>
  </el-tabs>

  </div>
  <autoSetting ref="autoSetting" @success="getList"/>
  <!-- 启动 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogTableVisible" title="启动方式" width="400px">
    <el-form>
      <el-form-item label="启动方式：">
        <el-select v-model="startType" placeholder="请选择启动方式" size="small" style="width:140px;">
          <el-option label="从头启动" :value="0"></el-option>
          <el-option label="从失败处启动" :value="1"></el-option>
          <el-option label="只记账" :value="2"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="dialogTableVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="startSure()">确定</el-button>
      </div>
    </template>
  </el-dialog>
  <autoTaxDetail ref="autoTaxDetail" from="kefu"/>
  <collectInvoiceDetail ref="collectInvoiceDetail"/>
  <sbImage ref="sbImage"/>
  <bankDeatils ref="bankDeatils" />
  <abnormalCom ref="abnormalCom"/>
  <autoShebaoDetail ref="autoShebaoDetail" />

</template>

<script>
import { companyAutomaticAdminList,startUpAutomatic,jumpAutomatic,confirmAutomatic ,automaticAdminStat ,confirmAutomaticBill ,orgAutomaticAdminList} from "@/api/automatic";
import { currentAccountPeriod } from '@/utils'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectcity from "@/components/Screening/selectcity";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import collectInvoiceDetail from "../../batch/components/collectInvoiceDetail.vue";
import autoSetting from './autoSetting.vue'
import autoTaxDetail from './autoTaxDetail.vue'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import sbImage from '@/components/sbImage'
import bankDeatils from "./bankDeatils.vue";
import abnormalCom from './abnormalCom.vue'
import autoHomeSelect from './autoHomeSelect.vue'
import autoShebaoDetail from './autoShebaoDetail.vue'
export default {
  name: "aiFinanceFactory",
  components: {
    selecttaxtype,
    selectcity,
    selectAccreditStatus,
    autoSetting,
    autoTaxDetail,
    collectInvoiceDetail,
    TagNameCopy,
    sbImage,
    bankDeatils,
    abnormalCom,
    autoHomeSelect,
    autoShebaoDetail
  },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        name:"",
        period:currentAccountPeriod(),
        accountingType: 2,
        statusTotal:null
      },
      contentStyleObj: {},
      list: [],
      total: 0,
      loading:false,
      sels:[],
      startType:0,
      dialogTableVisible:false,
      activeName:'税款申报',
      pageStatus: true,
      havingTask:false,
      info:{
        countTotal:0,
        countEmpower:0,
        countOk:0,
        countDqr:0,
        countErr:0,
        countJxz:0,
        countWks:0,
        countYc:0,
        countLsb:0,
        countSkSb:0,
        countWwc:0
      },
      orgList:[]
    };
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  beforeRouteLeave (to, from, next) {
    this.pageStatus = false
    next()
    // ...
  },
  created() {
    this.listQuery.limit = localStorage.getItem("aiFinanceFactory")
      ? localStorage.getItem("aiFinanceFactory") * 1
      : 20;
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(325);
    this.getList();
    this.initBus();
    this.init()
    this.getInfo();
    this.getOrgList()
  },
  methods: {
    getOrgList(){
      orgAutomaticAdminList({}).then(res=>{
        if(res.data.msg == 'success'){
          this.orgList = res.data.data.list
        }
      })
    },
    init(){
      this.$bus.off("confirmStatusUpdate")
      this.$bus.on("confirmStatusUpdate", (val) => {
        setTimeout(()=>{
          this.getList()
          this.getInfo()
        },2000)
      });
    },
    getInfo(){
      automaticAdminStat(this.listQuery).then(res=>{
        if(res.data.msg == 'success'){
          this.info = res.data.data
        }
      })
    },
    getNewList(){
      this.getList()
      this.getInfo()
    },
    getNewListTotal(e){
      this.listQuery.statusTotal = e ? e : null
      this.getNewList()
    },
    initBus(){
      this.$bus.off("refreshTask")
      this.$bus.on("refreshTask", (val) => {
        if(!this.pageStatus){
          return
        }
        if(this.sels.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        companyAutomaticAdminList(this.listQuery).then(res=>{
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        })
      });
    },
    getList() {
      this.loading = true
      companyAutomaticAdminList(this.listQuery).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      });
    },
    setting(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择公司',1)
        return
      }
      let ids = []
      this.sels.map(v=>{
        ids.push(v.comId)
      })
      this.$refs.autoSetting.init(ids)
    },
    //单独设置
    settingOne(row){
      this.$refs.autoSetting.init([row.comId])
    },
    handleSelectionChange(e){
      this.sels = e
    },
    //启动
    autoStart(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择公司',1)
        return
      }
      this.dialogTableVisible = true;
      this.startType = 0
    },
    startSure(){
      let ids = []
      this.sels.map(v=>{
        ids.push(v.comId)
      })
      let param = {
        comIds:ids,
        period:this.listQuery.period,
        type:this.startType
      }
      this.startTask(param)
    },
    //继续--失败处启动
    continueTask(row){
      this.$confirm('确定继续吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          comIds:[row.comId],
          period:this.listQuery.period,
          type:1
        }
        this.startTask(param,row)
      });
    },
    //检查处失败
    checkFailStart(row){
      this.$confirm('确定重启吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          comIds:[row.comId],
          period:this.listQuery.period,
          type:0
        }
        this.startTask(param,row)
      });
    },
    //重启
    reStart(){
      if(this.sels.length == 0){
        this.$qzfMessage('请选择公司',1)
        return
      }
      let ids = []
      this.sels.map(v=>{
        ids.push(v.comId)
      })
      let param = {
        comIds:ids,
        period:this.listQuery.period,
        type:0
      }
      this.startTask(param)
    },
    startTask(param,row){
      startUpAutomatic(param).then(res=>{
        if(res.data.msg == 'success'){
          if(row){
            row.btnStatus = true
            setTimeout(()=>{
              row.btnStatus = false
            },10*1000)
          }
          this.havingTask = true
          setTimeout(()=>{
            this.havingTask = false
          },10*1000)
          this.$qzfMessage('任务发起成功')
          this.dialogTableVisible = false
          this.getList()
          setTimeout(()=>{
            companyAutomaticAdminList(this.listQuery).then((res) => {
              if (res.data.msg == "success") {
                this.list = res.data.data.list ? res.data.data.list : [];
                this.total = res.data.data.total;
              }
            });
            this.getInfo()
          },5000)
        }
      })
    },
    //跳过步骤
    skipOption(row,type,text){
      this.$confirm('确定要跳过'+text+'吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        jumpAutomatic({
          comId:row.comId,
          period:this.listQuery.period,
          type:type
        }).then(res=>{
          if(res.data.msg == 'success'){
            this.$qzfMessage('成功跳过');
            this.getList()
          }
        })
      });
    },
    //确认
    confirmAuto(row,type,text){
      this.$confirm('确定要'+text+'吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        confirmAutomatic([{
          comId:row.comId,
          period:this.listQuery.period,
          type:type
        }]).then(res=>{
          if(res.data.msg == 'success'){
            this.$qzfMessage('操作成功');
            this.getList()
            this.$bus.emit('confirmStatusUpdate')
          }
        })
      });
    },
    //单据确认
    confirmBill(row,type,text){
      this.$confirm('确定要'+text+'吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        confirmAutomaticBill([{
          comId:row.comId,
          period:this.listQuery.period,
          type:type
        }]).then(res=>{
          if(res.data.msg == 'success'){
            this.$qzfMessage('操作成功');
            this.getList()
          }
        })
      });
    },
    //申报查看
    checkTableDialog(row,type){
      this.$refs.autoTaxDetail.init(row,this.listQuery.period,type)
    },
    //单据确认查看发票list
    checkInvoice(row){
      this.$refs.collectInvoiceDetail.init(row,this.listQuery.period)
    },
    //单据确认查看银行list
    checkBank(row){
      this.$refs.bankDeatils.init(row,this.listQuery.period,"auto")
    },
    handleClick(tab) {
      if(tab.props.name == "税款申报") {
        this.listQuery.accountingType = 2
        this.getList()
      }if(tab.props.name == "零申报") {
        this.listQuery.accountingType = 1
        this.getList()
      }
    },
    cancel(){
      let originLimit = this.listQuery.limit
      let originAccountingType = this.listQuery.accountingType
      this.listQuery= {
        page: 1,
        limit: originLimit,
        name:"",
        period:currentAccountPeriod(),
        accountingType: originAccountingType,
      }
      this.getList()
    },
    open(row, index,flag) {
      let imageAll = []
      this.list.map(res=>{
        let arr = []
        if(res[flag].match(',')){
          let imgUrls = res[flag].split(',')
          imgUrls.map(item=>{
            if(item){
              arr.push({
                img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                tableName:""
              })
            }
          })
        }else{
          if(res[flag]){
            arr.push({
              img:'https://file.listensoft.net' + res[flag] + "?" + Math.random(10),
              tableName:""
            })
          }
        }
        let url = {
          name:res.name,
          url:arr,
        }
        imageAll.push(url)
      })
      this.$refs.sbImage.dialog(imageAll, index,0)
    },
    getWjz(){
      this.$refs.abnormalCom.init(this.listQuery.period,'kefu',this.listQuery.orgId)
    },
    orgChange(e){
      this.listQuery.orgId = e ? e : null
      this.getNewList()
    },
    //社保查看
    checkShebao(row){
      let param = {
        comId:row.comId,
        period:this.listQuery.period,
        tableName:'tax-cj-shebao',
        comName:row.name,
      }
      this.$refs.autoShebaoDetail.openDialog(param)
    }
  },
};
</script>

<style scoped lang="scss">
.top_select{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }
  p{
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}

.text_width{
  width: 52px;
  display: inline-block
}
.icon-tiaoguo1{
  color: #17a2b8;
}
.style_form .el-form-item{
  margin-bottom:10px;
  .el-input{
    width: 140px;
    margin-left: 5px;
  }
}
.icon-a-xinjianzidingyichicun21x1{
  font-size: 15px;
  margin-right: 2px;
}
.icon-jiantou-you{
  font-weight: 600;
}
.div_p {
  line-height: 30px;
  border-bottom: 1px solid #efefef;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.auto_content_size{
  width: 91%;
  margin-left: 10px;
}
.each{
  width:120px !important;
}
</style>
